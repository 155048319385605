import React from "react";
import "./UserOnboardingWeightStep.scss";

const UserOnboardingWeightStep = (props) => {
  const { weight, handleChange } = props;
  return (
    <div className="row container-weight">
      <div className="col-6 container-img">
        {/* <img src="/assets/image/workout_girl.svg" alt="workout_girl.svg" /> */}
      </div>
      <div className="col-6 align-self-center">
        <h3>How much do you weigh?</h3>
        <div className="input-box">
          <input
            type="text"
            name="weight"
            value={weight}
            onChange={handleChange}
          />
          <span className="unit-text"> lb</span>
        </div>
      </div>
    </div>
  );
};

export default UserOnboardingWeightStep;
