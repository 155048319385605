import React, { useContext } from "react";
import "./BottomButtons.scss";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/globalState";

const NewBottomButtons = ({ step, next, prev, handleSubmit }) => {
  let circles = [];
  let checkActive = "";

  const { profile } = useContext(GlobalContext);
  const hasMeasurements = profile.TDEE !== "0" ? true : false;
  const numOfRec = hasMeasurements ? 1 : 2;

  for (let i = 0; i < numOfRec; ++i) {
    checkActive = i === step ? "rec active" : "rec";
    circles.push(<div className={checkActive} key={i} />);
  }
  return (
    <div className="btn-wrapper row">
      {step > 0 ? (
        <button className="btn btn-primary back" onClick={prev}>
          « Back
        </button>
      ) : (
        ""
      )}

      <div className="rec-container">{circles}</div>
      {step === 0 && !hasMeasurements ? (
        <button className="btn btn-secondary" onClick={next}>
          Next »
        </button>
      ) : (
        <button id="" className="btn btn-primary" onClick={handleSubmit}>
          Post Data
        </button>
      )}
    </div>
  );
};

export default NewBottomButtons;
