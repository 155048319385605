import React from "react";
import "./UserOnboardingSexStep.scss";
const UserOnboardingSexStep = (props) => {
  const { gender, handleChange } = props;
  let maleOpacity = gender === "male" ? 1 : 0.5;
  let femaleOpacity = gender === "female" ? 1 : 0.5;
  const handleSex = (gender) => {
    handleChange({ target: { name: "gender", value: gender } });
  };
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-6 container-sex"
          style={{ opacity: maleOpacity }}
          onClick={() => handleSex("male")}
        >
          <div className="btn btn-primary">Male</div>
          <div className="container-img">
            <img
              src="/assets/image/male.svg"
              className="img-fluid"
              alt="male"
            ></img>
          </div>
        </div>
        <div
          className="col-6 container-sex"
          style={{ opacity: femaleOpacity }}
          onClick={() => handleSex("female")}
        >
          <div className="btn btn-secondary">Female</div>
          <div className="container-img">
            <img
              src="/assets/image/female.svg"
              className="img-fluid"
              alt="female"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOnboardingSexStep;
