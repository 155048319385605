//@flow
import React, { Component, useContext, useState } from "react";
import UserOnboardingWeightStep from "../components/OnboardingSteps/UserOnboardingWeightStep";
import NewMeasurementCalc from "../components/OnboardingSteps/NewMeasurementCalc";
import { GlobalContext } from "../context/globalState";
import NewBottomButtons from "../components/OnboardingSteps/NewBottomButtons";
import "./UserOnboarding.scss";
import { Redirect } from "react-router-dom";

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  return {
    values,
    handleChange: (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    },
  };
};

const NewMeasurement = (props) => {
  const { postMeasurement, profile, measurementPostStatus } = useContext(
    GlobalContext
  );
  const [step, setStep] = useState(0);
  const data =
    props.location.state === undefined
      ? profile
      : props.location.state.template;
  const { values, handleChange } = useForm({
    ...data,
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    postMeasurement(values);
  };

  return (
    <div className="container-onboarding">
      {measurementPostStatus && <Redirect to="/dashboard"></Redirect>}
      {step === 0 ? (
        <UserOnboardingWeightStep
          weight={values.weight}
          handleChange={handleChange}
        />
      ) : (
          <NewMeasurementCalc
            BMRFormula={values.BMRFormula}
            pal={values.pal}
            TDEE={values.TDEE}
            weight={values.weight}
            carbInput={values.carbInput}
            fatInput={values.fatInput}
            proteinInput={values.proteinInput}
            persisted_name={values.persisted_name}
            height={values.height}
            gender={values.gender}
            age={values.age}
            handleChange={handleChange}
          />
        )}
      <NewBottomButtons
        step={step}
        next={nextStep}
        prev={prevStep}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default NewMeasurement;
