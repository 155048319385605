//@flow
import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Home.scss";

type HomeProps = {};
type HomeState = {};

class Home extends Component<HomeProps, HomeState> {
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className="home">
        <div className="backgroundTr">
          <div className="container-home container-fluid">
            <div className="header text-center">
              <div>
                <h1 className="txt-white">
                  <span>JUNK FOOD &nbsp; </span>
                  {"  "}
                  <span className="txt-primary"> &nbsp; REHAB</span>
                </h1>
              </div>
              <div className="img-container">
                <img src="assets/image/burgerSalad.svg"></img>
              </div>
            </div>

            <div className="input-container col-md-6 m-auto">
              <div className="btn-container">
                <Link to="/start">
                  <button type="submit" className="btn btn-login btn-block">
                    Start Your Rehab
                  </button>
                </Link>
              </div>

              <p className="text-center">OR</p>

              <div className="btn-container">
                <Link to={"/login"}>
                  {" "}
                  <button type="submit" className="btn btn-login btn-block">
                    Login
                  </button>
                </Link>
              </div>
              <p className="text-center">
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
