import React, { useState, useEffect, useContext } from "react";
import CalculatorCard from "../Cards/CalculatorCard";
import { GlobalContext } from "../../context/globalState";
import "./UserOnboardingCalculateStep.scss";
import { disableNext, enableNext } from "../Validation";

const UserOnboardingCalculateStep = (props) => {
  const [tdee, setTdee] = useState(0);
  const [missingInfo, setMissingInfo] = useState(false);

  const { editProfile, profile } = useContext(GlobalContext);

  function checkNullProps() {
    const check = Object.values(props).some((value) => value === "");
    setMissingInfo(check);
    return check;
  }

  useEffect(() => {
    //checkNullProps() ? disableNext() : enableNext();

    let height = parseInt(props.heightFeet) * 12 + parseInt(props.heightInch);
    let tdee = tdeeCalculator(props);

    props.handleChange({
      target: {
        name: "TDEE",
        value: tdee,
      },
    });
    editProfile({ ...props, height });
    return function cleanup() {};
  }, [props.persisted_name]);

  const tdeeCalculator = ({
    gender,
    weight,
    heightFeet,
    heightInch,
    age,
    pal,
  }) => {
    let kg = parseInt(weight / 2.205);
    let cm = parseInt(heightFeet * 30.48 + heightInch * 2.54);
    let bmrBase =
      gender === "man"
        ? 655 + 9.5 * kg + 1.8 * cm - 4.7 * parseInt(age)
        : 66 + 13.7 * kg + 5 * cm - 6.8 * parseInt(age);
    return Math.floor(bmrBase * parseInt(pal));
  };

  return (
    <div className="calculate">
      <div className="top-text">
        {missingInfo ? (
          <div>
            <h2>Some info is missing. Fill the missing info</h2>
          </div>
        ) : (
          <div>
            <h2>Recommended Caloric Intake</h2>
            <h2>{props.TDEE} kcal/day</h2>
          </div>
        )}
      </div>
      <CalculatorCard props={props} />
    </div>
  );
};

export default UserOnboardingCalculateStep;
