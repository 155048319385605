import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./Login.scss";
import { GlobalContext } from '../context/globalState';

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(GlobalContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await Auth.signIn(username, password);
      if (user) {
        localStorage.setItem("token", user.signInUserSession.idToken.jwtToken);
        login(user.signInUserSession.idToken.jwtToken);
        history.push("/dashboard");
      }
    } catch (error) {
      alert("Invalid email or password!");
    }
  };

  return (
    <div className="login">
      <div className="container-login container-fluid">
        <div className="header text-center">
          <h1>Login Page</h1>
        </div>
        <div className="input-container col-md-6 m-auto">
          <form className="input-form" onSubmit={onSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

            </div>

            <div className="btn-container">
              <button type="submit" className="btn btn-login btn-block">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
