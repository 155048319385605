//@flow
import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../context/globalState";
import DailyConsumption from "../components/DailyConsumption";
import CurrentMeasurement from "../components/CurrentMeasurement";
import MealModal from "../components/MealModal";
//import NewMeasurement from "./NewMeasurement";

type DashboardProps = {};
type DashboardState = {
  data: [mocks],
};

const Dashboard = () => {
  const { getProfile, mealPostStatus, initilizPostStatus } = useContext(
    GlobalContext
  );
  const [isModalSeen, toggleModal] = useState(false);

  useEffect(() => {
    getProfile();
    initilizPostStatus();
    return () => {};
  }, [mealPostStatus]);

  return (
    <div className="page">
      <DailyConsumption />
      <MealModal
        isModalSeen={isModalSeen}
        toggleModal={toggleModal}
      ></MealModal>
      <div className="eat-container">
        {false ? (
          <div>Add multistep eating form here</div>
        ) : (
          <div className="card1">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={() => toggleModal(!isModalSeen)}
            >
              {" "}
              Eat{" "}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
