//@flow
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/globalState";
import "./PreviousTemplateCard.scss";

const PreviousTemplateCard = () => {
  const { templates } = useContext(GlobalContext);
  console.log(templates);
  return (
    <div className="card">
      <div className="title">Measure</div>
      <div class="templatesBox">
        {templates.length > 0
          ? templates.map((template) => (
              <div className="template row">
                <div className="col-md-6 col-xs-12">
                  <div className="name"> {template.persisted_name} </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  {/* <div className="button"> {`Do This ${doThis}`} </div> */}
                  <Link
                    to={{ pathname: `/newmeasurement`, state: { template } }}
                  >
                    <div className="button">Do This</div>
                  </Link>
                </div>
              </div>
            ))
          : "No Saved Templates Yet"}
      </div>
    </div>
  );
};

export default PreviousTemplateCard;
