import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { GlobalContext } from "../context/globalState";
import { Auth } from "aws-amplify";

const UserConfirm = () => {
  const { user, postProfile, profile } = useContext(GlobalContext);
  const [code, setCode] = useState("");
  const [redirect, setRedirect] = useState(false);

  async function confirmSignUp(code) {
    try {
      const confirm = await Auth.confirmSignUp(user.username, code);

      if (confirm === "SUCCESS") {
        // "TODO- alert the user using dynamic css"
        postProfile({ ...profile, user_id: user.username });
        setRedirect(true);
      }
    } catch (error) {
      console.log("error confirming sign up", error);
      alert(error);
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    confirmSignUp(code);
  }

  return (
    <div className="confirmation">
      {redirect ? <Redirect to="/login" /> : ""}
      <div>Verification code has been sent to {user.username}.</div>
      <form className="input-form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Verification code"
            name="code"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            required
          ></input>
          <button type="submit" className="btn btn-login btn-block">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserConfirm;
