import React, { useState, useEffect, useContext } from "react";
import CalculatorCard from "../Cards/CalculatorCard";
import { GlobalContext } from "../../context/globalState";
import "./UserOnboardingCalculateStep.scss";
import { disableNext, enableNext } from "../Validation";

const NewMeasurementCalc = (props) => {
  const [missingInfo, setMissingInfo] = useState(false);

  const { editProfile } = useContext(GlobalContext);

  function checkNullProps() {
    const check = Object.values(props).some((value) => value === "");
    setMissingInfo(check);
    return check;
  }

  useEffect(() => {
    //checkNullProps() ? disableNext() : enableNext();

    // handleChange();
    props.handleChange({
      target: {
        name: "TDEE",
        value: tdeeCalculator(props),
      },
    });

    // editProfile({ ...props, height, TDEE: tdee });
    return function cleanup() {};
  }, []);

  const tdeeCalculator = ({ height, gender, weight, age, pal }) => {
    // 10 * weight (kg) + 6.25 * height (cm) - 5 * (age + 5)
    let kg = Math.floor(parseInt(weight) / 2.205);
    let cm = Math.floor(parseInt(height) * 2.54);
    let bmrBase =
      gender === "man"
        ? 655 + 9.5 * kg + 1.8 * cm - 4.7 * parseInt(age)
        : 66 + 13.7 * kg + 5 * cm - 6.8 * parseInt(age);
    return Math.floor(bmrBase * parseInt(pal));
  };

  return (
    <div className="calculate">
      <div className="top-text">
        {missingInfo ? (
          <div>
            <h2>Some info is missing. Fill the missing info</h2>
          </div>
        ) : (
          <div>
            <h2>Recommended Caloric Intake</h2>
            <h2>{props.TDEE} kcal/day</h2>
          </div>
        )}
      </div>
      <CalculatorCard props={props} />
    </div>
  );
};

export default NewMeasurementCalc;
