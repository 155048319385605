import React from "react";
import "./UserOnboardingDobStep.scss";
const UserOnboardingDobStep = (props) => {
  const { age, handleChange } = props;
  return (
    <div className="dob">
      <div className="container-img">
        <img
          src="/assets/image/jumping.svg"
          className="image-fluid"
          alt="dd"
        ></img>
      </div>
      <div className="container-input">
        <h3>Age</h3>
        <input type="text" name="age" value={age} onChange={handleChange} />
      </div>
    </div>
  );
};

export default UserOnboardingDobStep;
