//@flow
import React, { Component } from "react";
import "./CalculatorCard.scss";
import { nutritionValidate } from "../Validation";

type CalculatorCardProps = {
  BMRFormula: "String",
  pal: "String",
  handleChange: "Function",
  age: "String",
  heightInch: "String",
  heightFeet: "String",
  weight: "String",
  sex: "String",
};

class CalculatorCard extends Component<CalculatorCardProps> {
  render() {
    const {
      BMRFormula,
      pal,
      handleChange,
      carbInput,
      fatInput,
      proteinInput,
      persisted_name,
    } = this.props.props;

    return (
      <div className="card">
        <div className="row">
          <div className="title-head col-12">Caloric Calculator</div>
        </div>
        <div className="row">
          <div className="col-6 calculator-label">
            BMR Formula{" "}
            <div className="tooltip">
              <i className="fa fa-question-circle fa-2x" aria-hidden="true"></i>
              <span className="tooltiptext">Tooltip</span>
            </div>
          </div>
          <div className="col-6">
            <select
              name="BMRFormula"
              value={BMRFormula}
              onChange={handleChange}
            >
              <option value="mifflin">Mifflin-St Jeor</option>
              <option value="harris">Harris-Benedict</option>
              <option value="revised">Revised Harris-Benedict</option>
              <option value="katch">Katch-McArdle</option>
              <option value="schofiled">Schofield</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-6 calculator-label">Personal Activity Level</div>
          <div className="col-6">
            <select
              className="pal-select"
              name="pal"
              value={pal}
              onChange={handleChange}
            >
              <option value="1.2">
                You are sedentary and do little or no exercise
              </option>
              <option value="1.375">
                You exercise lightly or do sport 1-3 days/week
              </option>
              <option value="1.55">
                You are moderately active and do exercise or sport 3-5 days/week
              </option>
              <option value="1.725">
                You are very active with hard exercise or sport 6-7 days a week
              </option>
              <option value="1.9">
                You are extremely active with very hard exercise or sport and a
                physical job or training twice a day
              </option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-6 calculator-label">Carbs %</div>
          <div className="col-6">
            <input
              type="number"
              className="form-control"
              id="carbInput"
              placeholder="carbInput"
              name="carbInput"
              onChange={handleChange}
              onInput={nutritionValidate}
              value={carbInput}
              required
            ></input>
            <div className="invalid-feedback">
              The sum of carbs, fatInput, proteinInput can't be bigger 100.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 calculator-label">Fat %</div>
          <div className="col-6">
            <input
              type="number"
              className="form-control"
              id="fatInput"
              placeholder="fatInput"
              name="fatInput"
              onChange={handleChange}
              onInput={nutritionValidate}
              value={fatInput}
              required
            ></input>
            <div className="invalid-feedback">
              The sum of carbs, fat, proteinInput can't be bigger 100
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 calculator-label">Protein %</div>
          <div className="col-6">
            <input
              type="number"
              className="form-control"
              id="proteinInput"
              placeholder="proteinInput"
              name="proteinInput"
              onChange={handleChange}
              onInput={nutritionValidate}
              value={proteinInput}
              required
            ></input>
            <div className="invalid-feedback">
              The sum of carbs, fat, protein can't be bigger 100
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 calculator-label">
            Optional name to save as a template
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Add a name to save this measurement for future customization"
              name="persisted_name"
              onChange={handleChange}
              value={persisted_name}
              required
            ></input>
          </div>
        </div>
      </div>
    );
  }
}
export default CalculatorCard;
