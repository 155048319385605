import React from "react";
import "./BottomButtons.scss";
import { Link } from "react-router-dom";

const BottomButtons = ({ step, next, prev }) => {
  let circles = [];
  let checkActive = "";

  for (let i = 0; i < 6; ++i) {
    checkActive = i === step ? "rec active" : "rec";
    circles.push(<div className={checkActive} key={i} />);
  }
  return (
    <div className="btn-wrapper">
      {step > 0 ? (
        <button className="btn btn-primary back" onClick={prev}>
          « Back
        </button>
      ) : (
          ""
        )}

      <div className="rec-container">{circles}</div>
      {step !== 5 ? (
        <button className="btn btn-secondary" onClick={next}>
          Next »
        </button>
      ) : (
          <Link to="/signup">
            <button id="btn-signup" className="btn btn-secondary">
              Sign Up
          </button>
          </Link>
        )}
    </div>
  );
};

export default BottomButtons;
