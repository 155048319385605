function setValid(element) {
  element.classList.add("is-valid");
  element.classList.remove("is-invalid");
}
function setInvalid(element) {
  element.classList.add("is-invalid");
  element.classList.remove("is-valid");
}

function isEmailValid(email): bollean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function isPhoneValid(phone): bollean {
  const re = /^[+]?[0-9]{10,12}$/;
  return re.test(String(phone));
}

function idToInt(id) {
  return parseInt(document.getElementById(id).value);
}

export function disableNext() {
  document.getElementById("btn-signup").setAttribute("disabled", "");
}
export function enableNext() {
  document.getElementById("btn-signup").removeAttribute("disabled");
}

export function usernameValidate(e) {
  const element = document.getElementById("username");
  const email = document.getElementById("email");
  if (isEmailValid(e.target.value)) {
    setValid(element);
    email.setAttribute("disabled", true);
    email.setAttribute("placeholder", "Email is already used in username");
  } else if (isPhoneValid(e.target.value)) {
    setValid(element);
    email.removeAttribute("disabled");
    email.setAttribute("placeholder", "Email");
  } else {
    setInvalid(element);
  }
}

export function emailValidate(e) {
  const element = document.getElementById("email");
  if (isEmailValid(e.target.value)) {
    setValid(element);
  } else {
    setInvalid(element);
  }
}

export function passwordValidate(e) {
  const element = document.getElementById("password");
  if (e.target.value.length < 8) {
    setInvalid(element);
  } else {
    setValid(element);
  }
}
export function calorieValidate(e) {
  const calories = idToInt("calories");
  const carbs = idToInt("carbs");
  const fat = idToInt("fat");
  const protein = idToInt("protein");
  if (calories < carbs + fat + protein) {
    setInvalid(e.target);
  } else {
    setValid(e.target);
  }
}

export function nutritionValidate(e) {
  const carbs = idToInt("carbInput");
  const fat = idToInt("fatInput");
  const protein = idToInt("proteinInput");
  if (100 < carbs + fat + protein) {
    setInvalid(e.target);
  } else {
    setValid(e.target);
  }
}
