import React from "react";

const Home = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="account_balance_24px">
        <path
          id="icon/action/account_balance_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 7L12 2L21.5 7V9H2.5V7ZM12 4.26001L17.2101 7H6.79004L12 4.26001ZM5 11H7V18H5V11ZM13 11V18H11V11H13ZM2.5 20V22H21.5V20H2.5ZM17 11H19V18H17V11Z"
          fill="white"
          filloopacity="0.74"
        />
      </g>
    </svg>
  );
};
export { Home };
