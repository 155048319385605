import React from "react";
import { Home } from "./HomeIcon.js";
import { Login } from "./LoginIcon.js";
import { Dashboard } from "./DashboardIcon.js";
import { Measure } from "./MeasureIcon.js";

const Icon = (props) => {
  switch (props.name.toLowerCase()) {
    case "home":
      return <Home />;
    case "login":
      return <Login />;
    case "dashboard":
      return <Dashboard />;
    case "measure":
      return <Measure />;
    default:
      return <div />;
  }
};
export { Icon };
