import React, { Component } from "react";
import "./Consumption.scss";
import mocks from "../mocks/dashboardGET.json";

export default class LastEatenCard extends Component {
  constructor() {
    super();
    this.state = {
      data: [mocks],
    };
  }
  render() {
    return (
      <div>
        <div className="card1">
          <h3>Your last meal </h3>
          {this.state.data.map((item) => (
            <div>
              <h6>You Ate {item.last_eaten.food_name}</h6>
              <h6>Your Food url {item.last_eaten.food_url} </h6>
              <h6>Carbs consumed was {item.last_eaten.carbs} calories </h6>
              <h6>Fat consumed was {item.last_eaten.fat} calories</h6>
              <h6>Protein Consumed was {item.last_eaten.protein} calories</h6>
              <h6>
                Total Calories{" "}
                {item.last_eaten.fat +
                  item.last_eaten.carbs +
                  item.last_eaten.protein}{" "}
                calories
              </h6>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
