import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../context/globalState";
import LastEatenCard from "./LastEatenCard";
import DialKnobs from "./DialKnobs";
import mocks from "../mocks/dashboardGET.json";
import "./Consumption.scss";

const DailyConsumption = () => {
  const { profile } = useContext(GlobalContext);
  const [data, setdata] = useState([mocks]);
  const {
    carb_completed,
    carb_quota,
    fat_completed,
    fat_quota,
    protein_completed,
    protein_quota,
  } = profile;

  const returnPercen = (quota, target) => {
    return Math.floor((parseInt(target) / parseInt(quota)) * 100);
  };
  return (
    <div>
      <div className="card1">
        {carb_quota ? (
          <React.Fragment>
            <Link to="/newmeasurement" style={{ textDecoration: "none" }}>
              <h3>Your Daily Consumption</h3>
              <div className="row knob">
                <div className="col-md-4 col-xs-12" style={{ zIndex: "0" }}>
                  <DialKnobs
                    percentage={returnPercen(carb_quota, carb_completed)}
                    food="Carbs"
                  />
                </div>
                <div className="col-md-4 col-xs-12" style={{ zIndex: "0" }}>
                  <DialKnobs
                    percentage={returnPercen(fat_quota, fat_completed)}
                    food="Fat"
                  />
                </div>
                <div className=" col-md-4 col-xs-12" style={{ zIndex: "0" }}>
                  <DialKnobs
                    percentage={returnPercen(protein_quota, protein_completed)}
                    food="Protein"
                  />
                </div>
              </div>
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="row">
              <h3>
                {" "}
                Why don't we start by selecting a measurement goal for today?{" "}
              </h3>
              <br />
            </div>
            <div className="row">
              <Link to="/newmeasurement" className="btn btn-primary">
                Create a new measurement
              </Link>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default DailyConsumption;
