//@flow
import React, { Component } from "react";
import "./PersonalInfoCard.scss";

type PersonalInfoCardProps = {
  handleChange: "Function",
  age: "String",
  heightInch: "String",
  heightFeet: "String",
  weight: "String",
  gender: "String",
};

class PersonalInfoCard extends Component<PersonalInfoCardProps> {
  static defaultProps = {
    age: "demo",
    heightInch: "demo",
    heightFeet: "demo",
    weight: "demo",
    gender: "demo",
  };

  render() {
    const { age, heightInch, heightFeet, weight, gender } = this.props.profile;

    return (
      <div className="card">
        <div className="row">
          <div className="title-head col-12">Personal Information</div>
        </div>
        <div className="row">
          <div className="col-6 col-6 calculator-label">Sex</div>
          <div className="col-6">
            <div>{gender}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-6 calculator-label">Height</div>
          <div className="col-6">
            <div>
              {heightFeet} ft {heightInch} in
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-6 calculator-label">Weight</div>
          <div className="col-6">
            <div>{weight}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-6 calculator-label">Age</div>
          <div className="col-6">
            <div>{age}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default PersonalInfoCard;
