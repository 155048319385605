export default (state, action) => {
  switch (action.type) {
    case "EDIT_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };

    case "LEAD_TO_CONFIRM":
      return {
        ...state,
        user: action.payload,
      };
    case "POST_PROFILE":
      return {
        ...state,
        message: "Posted profile to the backend",
      };
    case "POST_MEASUREMENT":
      return {
        ...state,
        measurementPostStatus: action.payload,
      };

    case "POST_MEAL":
      return {
        ...state,
        mealPostStatus: action.payload,
      };
    case "GET_PROFILE":
      return {
        ...state,
        user: {
          user_id: action.payload.user_id,
        },
        profile: action.payload.template,
        templates: action.payload.templates,
        current_measurement: action.payload.current_measurement,
        history: action.payload.history,
      };
    case "INITIALIZE_POST_STATUS":
      return {
        ...state,
        mealPostStatus: false,
        measurementPostStatus: false,
      };

    default:
      return state;
  }
};
