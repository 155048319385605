import React, { Component } from "react";
import { Link } from "react-router-dom";

import CurrentMeasurementsCard from "../components/Cards/CurrentMeasurementCard.js";
import PreviousTemplateCard from "../components/Cards/PreviousTemplateCard.js";
import MeasurementChart from "../components/Cards/MeasurementChart.js";

class Measurements extends Component {
  render() {
    return (
      <div className="page">
        <CurrentMeasurementsCard />
        <PreviousTemplateCard />
        <MeasurementChart />
      </div>
    );
  }
}
export default Measurements;
