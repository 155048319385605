//@flow
import React, { Component, useContext } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { GlobalContext } from "../../context/globalState";
import "./MeasurementChart.scss";

const MeasurementChart = () => {
  const { history } = useContext(GlobalContext);
  return (
    <div className="card measurementChart">
      <div className="title">TDEE Consumed / Goal</div>
      <div className="chart-container">
        <ResponsiveContainer width={"99%"} height={300}>
          <LineChart
            data={history}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 10,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="date" />
            <YAxis type="number" domain={[0, "dataMax"]} />
            <Tooltip />
            <Legend verticalAlign="top" height={36} margin={30} />
            <Line
              type="monotone"
              dataKey="TDEE_completed"
              stroke="orangered"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="TDEE" stroke="#63af1f" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MeasurementChart;
