import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { GlobalContext } from "../context/globalState";
import "./MealModal.scss";
import { calorieValidate } from "./Validation";
const customStyles = {
  content: {
    // margin: "2rem",
    // padding: "1rem",
    borderRadius: "2rem",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const MealModal = (props) => {
  const [calories, setCalories] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);

  const { postMeal, template } = useContext(GlobalContext);
  const measurement_id = template.measurement_id;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function closeModal() {
    props.toggleModal(false);
  }

  function onSubmit(e) {
    e.preventDefault();
    postMeal({ calories, carbs, fat, protein, measurement_id });
    props.toggleModal(false);
  }

  return (
    <Modal
      isOpen={props.isModalSeen}
      contentLabel="Minimal Modal Example"
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="meal-modal">
        <div className="title text-center">What did you eat today?</div>
        <form className="input-form" onSubmit={onSubmit}>
          <div className="row meal-input">
            <label className="col-4">Calories</label>
            <div className="col-8">
              <input
                label
                type="number"
                className="form-control"
                id="calories"
                placeholder="calories"
                name="calories"
                onChange={(e) => setCalories(e.target.value)}
                value={calories}
                onInput={calorieValidate}
                required
              ></input>
              <div className="invalid-feedback">
                The sum of carbs, fat, protein can't be bigger than calories.
              </div>
            </div>
          </div>
          <div className="row meal-input">
            <label className="col-4">Carbs</label>
            <div className="col-8">
              <input
                type="number"
                className="form-control"
                id="carbs"
                placeholder="carbs"
                name="carbs"
                onChange={(e) => setCarbs(e.target.value)}
                onInput={calorieValidate}
                value={carbs}
                required
              ></input>
              <div className="invalid-feedback">
                The sum of carbs, fat, protein can't be bigger than calories.
              </div>
            </div>
          </div>
          <div className="row meal-input">
            <label className="col-4">Fat</label>
            <div className="col-8">
              <input
                type="number"
                className="form-control"
                id="fat"
                placeholder="fat"
                name="fat"
                onChange={(e) => setFat(e.target.value)}
                onInput={calorieValidate}
                value={fat}
                required
              ></input>
              <div className="invalid-feedback">
                The sum of carbs, fat, protein can't be bigger than calories.
              </div>
            </div>
          </div>
          <div className="row meal-input">
            <label className="col-4">Protein</label>
            <div className="col-8">
              <input
                type="number"
                className="form-control"
                id="protein"
                placeholder="protein"
                name="protein"
                onChange={(e) => setProtein(e.target.value)}
                value={protein}
                required
                onInput={calorieValidate}
              ></input>
              <div className="invalid-feedback">
                The sum of carbs, fat, protein can't be bigger than calories.
              </div>
            </div>
          </div>

          <div className="row meal-input">
            <button
              type="submit"
              className="btn btn-login btn-block btn-primary"
            >
              POST
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MealModal;
