import React, { useContext } from "react";
import { Icon } from "./Icons/Index";
import { Link } from "react-router-dom";
import { GlobalContext } from "../context/globalState";
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";

const Nav = () => {
  const history = useHistory();
  const { logout, token } = useContext(GlobalContext);

  async function signOut() {
    try {
      await Auth.signOut();
      localStorage.removeItem("token");
      logout(null);
      history.push("/login");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (
    <div className="snapnav">
      <nav>
        {" "}
        <ul>
          {token ? (
            <React.Fragment>
              {" "}
              <li>
                <Link to="/dashboard">
                  <Icon name="dashboard" width="25" height="25 " />
                  <br />
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to="/measure">
                  <Icon name="measure" width="25" height="25 " />
                  <br />
                  Measure
                </Link>
              </li>
              <li>
                <Link to="/newmeasurement">
                  <Icon name="measure" width="25" height="25 " />
                  <br />
                  Add Measurement
                </Link>
              </li>
              <li>
                <Link to="/login">
                  <div onClick={signOut}>
                    {/* {!user ? window.location.href = "/login" : ""} */}
                    <Icon name="home" width="25" height="25 " />
                    <br />
                    Logout
                  </div>
                </Link>
              </li>
            </React.Fragment>
          ) : (
              <React.Fragment>
                <li>
                  <Link to="/">
                    <Icon name="home" width="25" height="25 " />
                    <br />
                  Home
                </Link>
                </li>

                <li>
                  {" "}
                  <Link to="/login">
                    <Icon name="login" width="25" height="25 " />
                    <br />
                  Login
                  </Link>
                </li>
              </React.Fragment>
            )}
        </ul>
      </nav>
    </div>
  );
};
export default Nav;
