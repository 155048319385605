//@flow
import React, { useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import PersonalInfoCard from "../components/Cards/PersonalInfoCard";
import { GlobalContext } from "../context/globalState";
import {
  usernameValidate,
  emailValidate,
  passwordValidate,
} from "../components/Validation";
import "./Signup.scss";

const dummyProfile = {
  measurement_used: 0,
  carb_completed: 0,
  fat_completed: 0,
  protein_completed: 0,
  TDEE_completed: 0,
};

function Signup() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [nullForm, setNullForm] = useState(false);

  const { editProfile, profile, leadToConfirm } = useContext(GlobalContext);

  const DOMAIN = Auth._config.oauth.domain;
  const CLIENT_ID = Auth._config.aws_user_pools_web_client_id;

  const thirdPartySignupPath = (platform) => {
    return `https://${DOMAIN}/oauth2/authorize?identity_provider=${platform}&redirect_uri=http://localhost:3000/&response_type=CODE&client_id=${CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  };

  // const didMountRef = useRef(false);
  useEffect(() => {
    const check = Object.values(profile).some((value) => value === "");
    if (check) {
      setNullForm(true);
    }
    console.log(nullForm);
    return function cleanup() {
      // To pop up alert message only once
      if (nullForm) {
        alert(
          "There is missing inforamtion in the personal infomation. Redirecting to START"
        );
      }
    };
  });

  async function signUp() {
    try {
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      if (user) {
        editProfile({ ...profile, ...dummyProfile });
        leadToConfirm(user.user);
        setRedirect(true);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  function onSubmit(e) {
    e.preventDefault();
    signUp();
  }
  return (
    <div className="signup">
      {nullForm ? <Redirect to="/start" /> : ""}
      {redirect ? <Redirect to="/userconfirm" /> : ""}
      <div className="container-login container-fluid">
        <div className="input-container col-md-6 m-auto">
          <PersonalInfoCard profile={profile}></PersonalInfoCard>
          <form className="input-form" onSubmit={onSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="Email or Phone number"
                aria-describedby="inputGroupPrepend33"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                onInput={usernameValidate}
                required
              ></input>
              <div className="invalid-feedback">
                Username should be an email address or a phone number.
              </div>
              <div className="invalid-feedback">
                The phone number starts country code like +1
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Email / If the username is Email, can be blank"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                onInput={emailValidate}
                value={email}
              />
              <div className="invalid-feedback">
                The email address is not proper.
              </div>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onInput={passwordValidate}
                value={password}
                required
              />
              <div className="invalid-feedback">
                Password must contain at least 8 characters
              </div>
            </div>

            <div className="btn-container">
              <button type="submit" className="btn btn-login btn-block">
                GET STARTED
              </button>
            </div>

            <p className="text-center">OR</p>
          </form>
          <div className="btn-container">
            <Link
              to={{
                pathname: thirdPartySignupPath("Facebook"),
              }}
              target="_blank"
            >
              <button type="submit" className="btn btn-3rdauth btn-block">
                Continue with Facebook
                <i className="fa fa-facebook-square" aria-hidden="true"></i>
              </button>
            </Link>

            <Link
              to={{
                pathname: thirdPartySignupPath("Google"),
              }}
              target="_blank"
            >
              <button className="btn btn-3rdauth btn-block">
                Continuew with Google
                <i className="fa fa-google" aria-hidden="true"></i>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Signup;
