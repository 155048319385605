//@flow
import React, { Component, useContext } from "react";
import { GlobalContext } from "../../context/globalState";
import "./CurrentMeasurementCard.scss";

const CurrentMeasurementCard = (props) => {
  const { current_measurement } = useContext(GlobalContext);
  return (
    <div className="card">
      <div className="title">Current Measurement</div>
      <div class="measurementBox">
        <div className="measurement row">
          <div className="col-md-3 col-xs-12">
            <div className="name"> Date </div>
          </div>
          <div className="col-md-9 col-xs-12 d-flex justify-content-between">
            <div className=""> Calories </div>
            <div className=""> Carbs </div>
            <div className=""> Fat </div>
            <div className=""> Protein </div>
          </div>
        </div>
        {current_measurement.length > 0
          ? current_measurement.map((measurement) => (
              <div className="measurement row">
                <div className="col-md-3 col-xs-12">
                  <div className="name"> {measurement.time.slice(0, 10)} </div>
                </div>
                <div className="col-md-9 col-xs-12 d-flex justify-content-between">
                  <div className=""> {measurement.calories} </div>
                  <div className=""> {measurement.carbs} </div>
                  <div className=""> {measurement.fat} </div>
                  <div className=""> {measurement.protein} </div>
                </div>
              </div>
            ))
          : "No Saved Templates Yet"}
      </div>
    </div>
  );
};

export default CurrentMeasurementCard;
