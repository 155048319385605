//@flow
import React, { Component } from "react";
import UserOnboardingReadyStep from "../components/OnboardingSteps/UserOnboardingReadyStep";
import UserOnboardingSexStep from "../components/OnboardingSteps/UserOnboardingSexStep";
import UserOnboardingHeightStep from "../components/OnboardingSteps/UserOnboardingHeightStep";
import UserOnboardingWeightStep from "../components/OnboardingSteps/UserOnboardingWeightStep";
import UserOnboardingDobStep from "../components/OnboardingSteps/UserOnboardingDobStep";
import UserOnboardingCalculateStep from "../components/OnboardingSteps/UserOnboardingCalculateStep";
import BottomButtons from "../components/OnboardingSteps/BottomButtons";
import "./UserOnboarding.scss";

type UserOnboardingProps = {};
type UserOnboardingState = {
  step: "Number",
  age: "String",
  weight: "String",
  height: "String",
  BMRFormula: "String",
  pal: "String",
};

class UserOnboarding extends Component<
  UserOnboardingProps,
  UserOnboardingState
  > {
  state = {
    step: 0,
    TDEE: "0",
    age: "",
    weight: "",
    heightFeet: "",
    heightInch: "",
    BMRFormula: "mifflin",
    pal: "1",
    gender: "",
    carbInput: "",
    fatInput: "",
    proteinInput: "",
    persisted_name: "",
  };

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
  };

  prevStep = () => {
    this.setState({
      step: this.state.step - 1,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showStep = () => {
    const {
      step,
      gender,
      age,
      weight,
      heightInch,
      heightFeet,
      BMRFormula,
      pal,
      TDEE,
      carbInput,
      fatInput,
      proteinInput,
      persisted_name,
    } = this.state;

    switch (step) {
      case 0:
        return <UserOnboardingReadyStep />;
      case 1:
        return (
          <UserOnboardingSexStep
            handleChange={this.handleChange}
            gender={gender}
          />
        );
      case 2:
        return (
          <UserOnboardingDobStep handleChange={this.handleChange} age={age} />
        );
      case 3:
        return (
          <UserOnboardingHeightStep
            handleChange={this.handleChange}
            heightFeet={heightFeet}
            heightInch={heightInch}
          />
        );
      case 4:
        return (
          <UserOnboardingWeightStep
            handleChange={this.handleChange}
            weight={weight}
          />
        );
      case 5:
        return (
          <UserOnboardingCalculateStep
            BMRFormula={BMRFormula}
            pal={pal}
            TDEE={TDEE}
            handleChange={this.handleChange}
            gender={gender}
            heightInch={heightInch}
            heightFeet={heightFeet}
            weight={weight}
            age={age}
            carbInput={carbInput}
            fatInput={fatInput}
            proteinInput={proteinInput}
            persisted_name={persisted_name}
          />
        );
    }
  };

  render() {
    const { step } = this.state;

    return (
      <div className="container-onboarding">
        {this.showStep()}
        <BottomButtons step={step} next={this.nextStep} prev={this.prevStep} />
      </div>
    );
  }
}

export default UserOnboarding;
