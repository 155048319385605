import React, { useContext } from "react";
import "./UserOnboardingReadyStep.scss";
const UserOnboardingReadyStep = (props) => {
  return (
    <div className="container-ready">
      <div className="img-container">
        <img
          src="/assets/image/girl.svg"
          className="img-fluid "
          alt="Responsive image"
        ></img>
      </div>
      <h2 className="color-primary">Ready To Begin Your Junk Food Rehab?</h2>
      <p className="color-text">
        To begin the journey, we'll ask you a few question to determine your
        recommended daily calorie intake!
      </p>
    </div>
  );
};

export default UserOnboardingReadyStep;
