import React, { createContext, useReducer, useEffect } from "react";
import UserReducer from "./UserReducer";
import AuthReducer from "./AuthReducer";

const token = localStorage.getItem("token");

const initialState = {
  user: {},
  profile: {
    cognito_hash: "",
    gender: "",
    age: "",
    height: "",
    weight: "",
    measurement_id: "",
    TDEE: "0",
    carb_quota: "",
    fat_quota: "",
    protein_quota: "",
    persisted_name: "",
    date: "",
    measurement_used: "",
    TDEE_completed: "",
    carb_completed: "",
    fat_completed: "",
    protein_completed: "",
  },
  history: "",
  current_measurement: "",
  mealPostStatus: "",
  measurementPostStatus: "",
  template: "",
  templates: "",
  token: token,
};

const URL = "https://a7lplntfs3.execute-api.us-west-2.amazonaws.com/api/";

export const GlobalContext = createContext(initialState);

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const [auth, authDispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => { }, [state, auth]);

  async function postProfile(userProfile) {
    const response = await fetch(URL + "/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userProfile),
    }).catch((err) => console.log(err));
    const data = await response.json();
    dispatch({
      type: "POST_PROFILE",
      payload: data,
    });
    return data.message;
  }
  async function postMeasurement(measurement) {
    const response = await fetch(URL + "/measurements", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
      body: JSON.stringify(measurement),
    }).catch((err) => console.log(err));
    const data = await response.json();
    dispatch({
      type: "POST_MEASUREMENT",
      payload: data.message,
    });
  }

  async function postMeal(mealData) {
    console.log(mealData);
    const response = await fetch(URL + "/meals", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...mealData,
        measurement_id: state.profile.measurement_id,
      }),
    }).catch((err) => console.log(err));
    const data = await response.json();
    dispatch({
      type: "POST_MEAL",
      payload: data.message === "success" ? true : false,
    });
  }

  async function getProfile() {
    const response = await fetch(URL + "/get-measurements", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
      // body: userProfile,
    });
    const data = await response.json();
    dispatch({
      type: "GET_PROFILE",
      payload: {
        templates: data.data.templates,
        template: data.data.templates.slice(-1)[0],
        current_measurement: data.data.current_measurement,
        history: data.data.history,
      },
    });
  }

  const editProfile = (profile) => {
    dispatch({
      type: "EDIT_PROFILE",
      payload: profile,
    });
  };
  const leadToConfirm = (userData) => {
    // console.log();
    dispatch({
      type: "LEAD_TO_CONFIRM",
      payload: userData,
    });
  };

  const initilizPostStatus = () => {
    dispatch({
      type: "INITIALIZE_POST_STATUS",
    });
  };

  const login = (token) => {
    authDispatch({
      type: "LOG_IN",
      payload: token
    });
  };

  const logout = (token) => {
    authDispatch({
      type: "LOG_OUT",
      payload: token
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        profile: state.profile,
        user: state.user,
        mealPostStatus: state.mealPostStatus,
        measurementPostStatus: state.measurementPostStatus,
        current_measurement: state.current_measurement,
        history: state.history,
        template: state.template,
        templates: state.templates,
        token: auth.token,
        editProfile,
        leadToConfirm,
        postProfile,
        postMeasurement,
        getProfile,
        postMeal,
        initilizPostStatus,
        login,
        logout
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
