import React from "react";
import logo from "./logo.svg";
import "./App.scss";

import Nav from "./components/Nav";

import Home from "./pages/Home";
import Measurements from "./pages/Measurements";
import UserOnboarding from "./pages/UserOnboarding";
import NewMeasurement from "./pages/NewMeasurement";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import UserConfirm from "./pages/UserConfirm";

import { Auth } from "aws-amplify";

import { GlobalContextProvider } from "./context/globalState";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
// TODO- once cognito auth is resolved, we can update this to global context and pull in real user data
// For now, just update this to true/ false to achieve behavioral results
// const fakeAuth = {
//   isAuthenticated: true,
// };

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("token") ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

function App() {
  return (
    <GlobalContextProvider>
      <Router>
        <Nav />
        <div className="content">
          <Switch>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="/start">
              <UserOnboarding />
            </Route>
            <PrivateRoute path="/measure">
              <Measurements />
            </PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/userconfirm">
              <UserConfirm />
            </Route>
            <PrivateRoute path="/newmeasurement" component={NewMeasurement}>
              {/* <NewMeasurement /> */}
            </PrivateRoute>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </GlobalContextProvider>
  );
}

export default App;
