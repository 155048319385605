import React, { useState } from "react";
import { Donut } from "react-dial-knob";

export default function DialKnobs(props) {
  const [value] = React.useState();
  return (
    <Donut
      diameter={200}
      min={0}
      max={100}
      step={1}
      value={props.percentage}
      theme={{
        donutColor: " #63af1f",
      }}
      ariaLabelledBy={"my-label"}
    >
      <label
        id={"my-label"}
        className="knobLabel"
      >
        {props.food}
      </label>
    </Donut>
  );
}
