import React from "react";

const Measure = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="straighten_24px">
        <path
          id="icon/image/straighten_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6H21C22.1 6 23 6.89999 23 8V16C23 17.1 22.1 18 21 18H3C1.90002 18 1 17.1 1 16V8C1 6.89999 1.90002 6 3 6ZM3 16H21V8H19V12H17V8H15V12H13V8H11V12H9V8H7V12H5V8H3V16Z"
          fill="white"
          fillOpacity="0.74"
        />
      </g>
    </svg>
  );
};
export { Measure };
