import React from "react";
import "./UserOnboardingHeightStep.scss";
const UserOnboardingHeightStep = (props) => {
  const { heightInch, heightFeet, handleChange } = props;
  return (
    <div className="row container-height">
      <div className="col-8 height-text-box">
        <h3>How tall are you?</h3>
        <div className="input-box">
          <div>
            <input
              type="text"
              name="heightFeet"
              value={heightFeet}
              onChange={handleChange}
            />
            <span className="unit-text">feet</span>
          </div>
          <div>
            <input
              type="text"
              name="heightInch"
              value={heightInch}
              onChange={handleChange}
            />
            <span className="unit-text">inches</span>
          </div>
        </div>
      </div>
      <div className="col-4 container-img">
        <img src="/assets/image/man_height.png" alt="workout_girl.svg" />
      </div>
    </div>
  );
};

export default UserOnboardingHeightStep;
